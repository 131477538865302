import { isNullOrUndefined } from "@/utils/general";
import { defineStore } from "pinia";
import { computed } from "vue";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import {
  Action,
  AnyDict,
  FormInput,
  Job,
  Route,
  RouteActionAssociation,
  RouteTaskAssociation,
  Schedule,
  ScheduleItem,
  Task,
  TaskActionAssociation,
} from "../types/general";
import { Permission } from "./auth";

interface State {
  currentRobotActions: Array<Action>;
  currentRobotTasks: Array<Task>;
  currentRobotRoutes: Array<Route>;
  currentRobotSchedule: Schedule;
}

export const useJobStore = defineStore("job", {
  state: (): State => {
    return {
      currentRobotActions: [],
      currentRobotTasks: [],
      currentRobotRoutes: [],
      currentRobotSchedule: {},
    };
  },
  getters: {
    scheduleInputs(state) {
      return computed(() => {
        let jobOptions = state.currentRobotActions.map((job: Action) => ({
          label: job.name,
          value: job.id,
        }));
        jobOptions = [
          ...state.currentRobotTasks.map((job: Task) => ({
            label: job.name,
            value: job.id,
          })),
          ...jobOptions,
        ];
        jobOptions = [
          ...state.currentRobotRoutes.map((job: Route) => ({
            label: job.name,
            value: job.id,
          })),
          ...jobOptions,
        ];

        return [
          [
            {
              field: "job_id",
              fieldType: InputFieldType.int,
              title: "jobId",
              type: InputType.dropdown,
              options: jobOptions,
              required: true,
              readLevel: Permission.User,
              editLevel: Permission.User,
            },
          ],
          [
            {
              field: "recurrent",
              fieldType: InputFieldType.bool,
              title: "recurrent",
              default: true,
              type: InputType.checkbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
            },
          ],
          [
            {
              field: "day",
              fieldType: InputFieldType.int,
              title: "day",
              dependsOnInput: {
                field: "recurrent",
                value: true,
              },
              required: true,
              type: InputType.dropdown,
              options: [
                { label: "sunday", value: 6 },
                { label: "monday", value: 0 },
                { label: "tuesday", value: 1 },
                { label: "wednesday", value: 2 },
                { label: "thursday", value: 3 },
                { label: "friday", value: 4 },
                { label: "saturday", value: 5 },
              ],
              readLevel: Permission.User,
              editLevel: Permission.User,
            },
          ],
          [
            {
              field: "date",
              fieldType: InputFieldType.string,
              title: "date",
              dependsOnInput: {
                field: "recurrent",
                value: false,
              },
              required: true,
              type: InputType.textbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
              placeHolder: "YYYY-MM-DD",
            },
          ],
          [
            {
              field: "start_time",
              fieldType: InputFieldType.string,
              title: "startTime",
              required: true,
              type: InputType.textbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
              placeHolder: "HH:MM",
            },
            {
              field: "end_time",
              fieldType: InputFieldType.string,
              title: "endTime",
              required: true,
              type: InputType.textbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
              placeHolder: "HH:MM",
            },
          ],
          [
            {
              field: "infinite",
              fieldType: InputFieldType.bool,
              title: "infinite",
              type: InputType.checkbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
            },
          ],
          [
            {
              field: "repeat_times",
              fieldType: InputFieldType.int,
              title: "repeatTimes",
              required: true,
              type: InputType.textbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
              default: 1,
              dependsOnInput: {
                field: "infinite",
                value: false,
              },
            },
          ],
          [
            {
              field: "stop_on_clear",
              fieldType: InputFieldType.bool,
              title: "stopOnClear",
              type: InputType.checkbox,
              readLevel: Permission.User,
              editLevel: Permission.User,
            },
          ],
        ] as Array<Array<FormInput>>;
      });
    },
    baseInputs: () =>
      [
        [
          {
            field: "name",
            fieldType: InputFieldType.string,
            title: "name",
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "ui_display",
            fieldType: InputFieldType.object,
            title: "uiDisplay",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "starting_conditions",
            fieldType: InputFieldType.object,
            title: "startingConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "ending_conditions",
            fieldType: InputFieldType.object,
            title: "endingConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>,
    routeAndTaskInputs: () =>
      [
        [
          {
            field: "led",
            fieldType: InputFieldType.object,
            title: "led",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "success_conditions",
            fieldType: InputFieldType.object,
            title: "successConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_start",
            fieldType: InputFieldType.object,
            title: "onStart",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_success",
            fieldType: InputFieldType.object,
            title: "onSuccess",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_failure",
            fieldType: InputFieldType.object,
            title: "onFailure",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>,

    actionInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push([
        {
          field: "behaviour",
          fieldType: InputFieldType.object,
          title: "behaviour",
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ]);
      return inputs;
    },
    taskInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push(...this.routeAndTaskInputs);
      inputs.push([
        {
          field: "action_keys",
          fieldType: InputFieldType.array,
          title: "actionKeys",
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ]);
      return inputs;
    },
    routeInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push(...this.routeAndTaskInputs);
      inputs.push(
        [
          {
            field: "restart_required",
            fieldType: InputFieldType.bool,
            title: "restartRequired",
            type: InputType.checkbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "all_association_keys",
            fieldType: InputFieldType.array,
            title: "allAssociationKeys",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ]
      );
      return inputs;
    },
  },
  actions: {
    async fetchSchedule(robot_id: number) {
      const res = await httpCommon.get("schedules/ui/" + robot_id);
      if (res.data) {
        this.currentRobotSchedule = res.data;
      }
    },
    async patchScheduleItem(schedule_item: ScheduleItem) {
      await httpCommon.patch("schedules/ui/" + this.currentRobotSchedule.id, schedule_item);
    },
    async deleteScheduleItem(item_id: number, robot_uuid: string) {
      await httpCommon.delete("schedules/ui/" + item_id + "/" + robot_uuid);
    },
    createAssociation(
      newAssociationKeys: Array<AnyDict>,
      parentJobType: string,
      parentId: number,
      parentKey: string,
      childKeyProp: string
    ) {
      const newAssociations: Array<RouteTaskAssociation | RouteActionAssociation | TaskActionAssociation> = [];
      newAssociationKeys.forEach(({ key, index }) => {
        const association: AnyDict = {
          [`${parentJobType}_id`]: parentId,
          [`${parentJobType}_key`]: parentKey,
          order: index,
        };
        association[childKeyProp] = key;
        newAssociations.push(association as RouteTaskAssociation | RouteActionAssociation | TaskActionAssociation);
      });
      return newAssociations;
    },
    updateAssociations(
      job: Route | Task,
      associationKeysProp: string,
      associations: Array<RouteTaskAssociation | RouteActionAssociation | TaskActionAssociation>,
      keyProp: string
    ) {
      const keyOrders: Record<string, Array<number>> = {};
      job[associationKeysProp as keyof typeof job]?.forEach((key: string, order: number) => {
        if (!keyOrders[key]) keyOrders[key] = [];
        keyOrders[key].push(order);
      });
      return associations
        ?.map((a) => {
          const key = a[keyProp as keyof typeof a];
          if (key && keyOrders[key]?.length) {
            a.order = keyOrders[key].shift() as number;
            return a;
          }
          return null;
        })
        .filter((a) => a !== null);
    },
    async fetchJobs(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/" + robot_id);
      if (res.data) {
        this.currentRobotActions = res.data.filter((job: Job) => job.job_type === "Action") || [];
        this.currentRobotTasks.values = res.data.filter((job: Job) => job.job_type === "Task") || [];
        this.currentRobotRoutes = res.data.filter((job: Job) => job.job_type === "Route") || [];
      }
    },
    async fetchActions(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/actions/" + robot_id);
      if (!isNullOrUndefined(res.data)) {
        res.data.forEach((action: Action) => {
          action.route_keys = action.route_associations?.map((raa: RouteActionAssociation) => raa.route_key as string);
          action.task_keys = action.task_associations?.map((taa: TaskActionAssociation) => taa.task_key as string);
        });
      }
      this.currentRobotActions = res.data || [];
      return res.data;
    },
    async postAction(action: Action, robot_id: number) {
      await httpCommon.post("jobs/ui/action/" + robot_id, action);
    },
    async patchAction(action: Action, robot_id: number, robot_uuid: string) {
      await httpCommon.patch(`jobs/ui/action/${robot_id}`, { action, robot_uuid });
    },
    async deleteAction(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/action/" + id + "/" + robot_uuid);
    },
    async fetchTasks(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/tasks/" + robot_id);
      this.currentRobotTasks = res.data ? res.data : [];
      if (!isNullOrUndefined(res.data)) {
        res.data.forEach((task: Task) => {
          task.route_keys = task.route_associations?.map((raa: RouteTaskAssociation) => raa.route_key as string);
          task.action_keys = task.action_associations?.map((taa: TaskActionAssociation) => taa.action_key as string);
        });
      }
      return res.data;
    },
    async postTask(task: Task, robot_id: number) {
      task.action_associations = [];
      const new_taa_keys = (task.action_keys as string[]).map((key, i) => ({ key, index: i }));
      task.action_associations = this.createAssociation(
        new_taa_keys,
        "task",
        task.id,
        task.key,
        "action_key"
      ) as Array<TaskActionAssociation>;
      console.log(task.action_associations);
      await httpCommon.post("jobs/ui/task/" + robot_id, task);
    },
    async patchTask(task: Task, robot_id: number, robot_uuid: string) {
      task.action_associations = task.action_associations
        ? (this.updateAssociations(
            task,
            "action_keys",
            task.action_associations,
            "action_key"
          ) as Array<TaskActionAssociation>)
        : [];
      const new_taa_keys = (task.action_keys as string[])
        .map((key, i) => ({ key, index: i }))
        .filter(
          ({ key, index }) =>
            key.startsWith("action_") &&
            !task.action_associations?.some((taa) => taa.order === index && taa.action_key === key)
        );

      task.action_associations.push(
        ...(this.createAssociation(
          new_taa_keys,
          "task",
          task.id,
          task.key,
          "action_key"
        ) as Array<TaskActionAssociation>)
      );
      await httpCommon.patch(`jobs/ui/task/${robot_id}`, { task, robot_uuid });
    },
    async deleteTask(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/task/" + id + "/" + robot_uuid);
    },
    async fetchRoutes(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/routes/" + robot_id);
      this.currentRobotRoutes = res.data || [];
      if (res.data) {
        res.data.forEach((route: Route) => {
          route.task_keys = route.task_associations?.map((rta: RouteTaskAssociation) => rta.task_key as string);
          route.action_keys = route.action_associations?.map((raa: RouteActionAssociation) => raa.action_key as string);

          route.all_association_keys = [
            ...(route.task_associations?.map((rta: RouteTaskAssociation) => ({
              key: rta.task_key as string,
              order: rta.order,
            })) || []),
            ...(route.action_associations?.map((raa: RouteActionAssociation) => ({
              key: raa.action_key as string,
              order: raa.order,
            })) || []),
          ]
            .sort((a, b) => a.order - b.order) // Sort by the order field
            .map((item) => item.key);
        });
      }
      return res.data;
    },
    async postRoute(route: Route, robot_id: number) {
      route.task_associations = [];
      route.action_associations = [];
      const all_associations = Array.isArray(route.all_association_keys) ? route.all_association_keys : [];
      const new_rta_keys = all_associations
        .map((key, i) => ({ key, index: i }))
        .filter(({ key }) => key.startsWith("task_"));
      route.task_associations = this.createAssociation(
        new_rta_keys,
        "route",
        route.id,
        route.key,
        "task_key"
      ) as Array<RouteTaskAssociation>;

      const new_raa_keys = all_associations
        .map((key, i) => ({ key, index: i }))
        .filter(({ key }) => key.startsWith("action_"));

      route.action_associations = this.createAssociation(
        new_raa_keys,
        "route",
        route.id,
        route.key,
        "action_key"
      ) as Array<RouteActionAssociation>;

      delete route.all_association_keys;
      await httpCommon.post("jobs/ui/route/" + robot_id, route);
    },
    async patchRoute(route: Route, robot_id: number, robot_uuid: string) {
      route.task_associations = route.task_associations
        ? (this.updateAssociations(
            route,
            "all_association_keys",
            route.task_associations,
            "task_key"
          ) as Array<RouteTaskAssociation>)
        : [];
      route.action_associations = route.action_associations
        ? (this.updateAssociations(
            route,
            "all_association_keys",
            route.action_associations,
            "action_key"
          ) as Array<RouteActionAssociation>)
        : [];

      const new_rta_keys = (route.all_association_keys as string[])
        .map((key, i) => ({ key, index: i }))
        .filter(
          ({ key, index }) =>
            key.startsWith("task_") &&
            !route.task_associations?.some((rta) => rta.order === index && rta.task_key === key)
        );

      route.task_associations.push(
        ...(this.createAssociation(
          new_rta_keys,
          "route",
          route.id,
          route.key,
          "task_key"
        ) as Array<RouteTaskAssociation>)
      );

      const new_raa_keys = (route.all_association_keys as string[])
        .map((key, i) => ({ key, index: i }))
        .filter(
          ({ key, index }) =>
            key.startsWith("action_") &&
            !route.action_associations?.some((raa) => raa.order === index && raa.action_key === key)
        );

      route.action_associations.push(
        ...(this.createAssociation(
          new_raa_keys,
          "route",
          route.id,
          route.key,
          "action_key"
        ) as Array<RouteActionAssociation>)
      );
      await httpCommon.patch(`jobs/ui/route/${robot_id}`, { route, robot_uuid });
    },
    async deleteRoute(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/route/" + id + "/" + robot_uuid);
    },
  },
  persist: {
    storage: sessionStorage,
  },
});

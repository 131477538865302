import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow } from "vue"

const _hoisted_1 = ["placeholder", "readonly"]
const _hoisted_2 = { class: "dropdownContainer" }
const _hoisted_3 = { class: "dropdownList" }
const _hoisted_4 = ["id", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.dropdownType === _ctx.DropdownTypes.cardDropdown ? 'cardDropdown' : 'formDropdown'),
    onFocusout: _cache[7] || (_cache[7] = ($event: any) => {
      _ctx.dropdownOpened = false;
      _ctx.filterText = '';
    })
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
      placeholder: _ctx.selectedOption?.label,
      readonly: _ctx.dropdownType === _ctx.DropdownTypes.formDropdown,
      class: _normalizeClass(_ctx.dropdownType !== _ctx.DropdownTypes.cardDropdown ? 'select' : 'dropdownInput'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => {if (_ctx.dropdownType !== _ctx.DropdownTypes.cardDropdown) _ctx.toggleOpened();}),
      onKeyup: [
        _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.moveSelection('down')), ["down"])),
        _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.moveSelection('up')), ["up"])),
        _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.selectOption()), ["enter"]))
      ]
    }, null, 42, _hoisted_1), [
      [_vModelText, _ctx.filterText]
    ]),
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass([_ctx.dropdownOpened ? 'dropdownCollapsed' : 'dropdownExpanded']),
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleOpened()))
    }, null, 2)),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(_ctx.hoveredOptionIndex == index ? 'hoveredOption' : 'option'),
            id: _ctx.key.toString() + '_' + index.toString(),
            key: option.value,
            onMouseenter: ($event: any) => (_ctx.hoveredOptionIndex = index),
            onMousedown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectOption()))
          }, _toDisplayString(option.label), 43, _hoisted_4))
        }), 128))
      ])
    ], 512), [
      [_vShow, _ctx.dropdownOpened]
    ])
  ], 34))
}
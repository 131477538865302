<template>
  <div
    :class="dropdownType === DropdownTypes.cardDropdown ? 'cardDropdown' : 'formDropdown'"
    @focusout="
      dropdownOpened = false;
      filterText = '';
    "
  >
    <input
      v-model="filterText"
      :placeholder="selectedOption?.label"
      :readonly="dropdownType === DropdownTypes.formDropdown"
      :class="dropdownType !== DropdownTypes.cardDropdown ? 'select' : 'dropdownInput'"
      @click="if (dropdownType !== DropdownTypes.cardDropdown) toggleOpened();"
      @keyup.down="moveSelection('down')"
      @keyup.up="moveSelection('up')"
      @keyup.enter="selectOption()"
    />
    <svg :class="[dropdownOpened ? 'dropdownCollapsed' : 'dropdownExpanded']" @click="toggleOpened()"></svg>
    <div class="dropdownContainer" v-show="dropdownOpened">
      <ul class="dropdownList">
        <li
          v-for="(option, index) in filteredOptions"
          :class="hoveredOptionIndex == index ? 'hoveredOption' : 'option'"
          :id="key.toString() + '_' + index.toString()"
          :key="option.value"
          @mouseenter="hoveredOptionIndex = index"
          @mousedown="selectOption()"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { isNullOrUndefined } from "@/utils/general";
import { PropType, computed, defineComponent, nextTick, ref, watch } from "vue";

import { DropdownOption } from "../../../types/general";

/* eslint-disable no-unused-vars */
export enum DropdownTypes {
  cardDropdown = 0,
  formDropdown = 1,
  filterFormDropdown = 2,
}
/* eslint-enable no-unused-vars */

export default defineComponent({
  name: "Dropdown",
  props: {
    options: { required: true, type: Array as PropType<DropdownOption[]> },
    parentSelectedOption: { required: false, type: Object as PropType<DropdownOption> },
    dropdownType: { required: false, type: Number as PropType<DropdownTypes>, default: DropdownTypes.cardDropdown },
  },
  emits: ["optionSelected"],
  setup(props, { emit }) {
    const key = Math.random();
    let filterText = ref("");
    let selectedOption = ref(props.parentSelectedOption as DropdownOption);
    let hoveredOptionIndex = ref(0);
    let dropdownOpened = ref(false);

    const filteredOptions = computed(() => {
      if (!filterText.value) {
        return props.options;
      }
      const filter = filterText.value.toLowerCase();
      return props.options.filter((option) => option.label.toLowerCase().includes(filter));
    });

    function resetHoveredOption() {
      if (!isNullOrUndefined(selectedOption.value)) {
        const option_values = filteredOptions.value.map((o) => o.value);
        hoveredOptionIndex.value = option_values.indexOf(selectedOption.value.value);
      }
      if (hoveredOptionIndex.value == -1) hoveredOptionIndex.value = 0;
    }

    function toggleOpened() {
      dropdownOpened.value = !dropdownOpened.value;
      if (dropdownOpened.value) {
        resetHoveredOption();
        nextTick(() => scrollToHoveredOption());
      }
    }

    function moveSelection(direction: string) {
      if (direction === "up" && hoveredOptionIndex.value > 0) {
        hoveredOptionIndex.value -= 1;
      } else if (direction === "down" && hoveredOptionIndex.value < filteredOptions.value.length) {
        hoveredOptionIndex.value += 1;
      }
      scrollToHoveredOption();
    }

    function selectOption() {
      selectedOption.value = filteredOptions.value[hoveredOptionIndex.value];
      dropdownOpened.value = false;
      emit("optionSelected", filteredOptions.value[hoveredOptionIndex.value]);
    }

    function scrollToHoveredOption() {
      const element = document.getElementById(key.toString() + "_" + hoveredOptionIndex.value.toString());
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    resetHoveredOption();
    watch(
      () => props.parentSelectedOption,
      (newval) => {
        selectedOption.value = newval as DropdownOption;
        resetHoveredOption();
      }
    );

    watch(
      () => filterText.value,
      () => {
        nextTick(() => {
          resetHoveredOption();
          scrollToHoveredOption();
        });
      }
    );

    return {
      key,
      dropdownOpened,
      filterText,
      filteredOptions,
      selectOption,
      selectedOption,
      DropdownTypes,
      moveSelection,
      hoveredOptionIndex,
      toggleOpened,
    };
  },
});
</script>

<style scoped lang="scss" src="./dropdown.scss"></style>
